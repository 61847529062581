<template>
  <div class="about">
    <h1>about</h1>
    <img alt="Vue logo" src="../assets/logo.png">
    <input type="text" v-on:focus="tttt">
    <p>about about about about</p>
  </div>
</template>

<script>
export default {
  name: 'About',
  methods: {
    tttt () {
      console.log('wwwwwww')
    }
  }
}
</script>
